.img-display {
  /* Photo frame styles */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  /* width: 340px; */
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.3s ease;
  position: relative; /* Needed for positioning the photo name */
}

.img-display:hover {
  transform: scale(1.05);
}

.img-display::after {
  content: "Photo Name";
  position: absolute;
  bottom: -30px; /* Adjust the vertical position as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.img-display:hover::after {
  opacity: 1;
}
.swiper-cube {
  /* width: '80%';
  height: 800px; */
  /* position: absolute; */
  /* left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px; */
  background-color: transparent !important;
}

.swiper-slide-cube {
  background-position: center !important;
  background-size: cover !important;
  padding: 0 !important;
}